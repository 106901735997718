export const PATIENT_PATTERN = /^\/patient\/(.*)$/
export const ENCOUNTERS_PATTERN = /^\/encounters\/(.*)$/

export const DATE_FORMAT_PARAMETER = { day: "numeric", month: "short", year: "numeric", weekday: "short", timeZone: undefined}

export const ENCOUNTER_NOTE = "notes";
export const ENCOUNTER_QME = "qme";

export const VAPID_KEY = "BAKkQ4w0g830tiOWn8dQKitR6uB96B-eC6X8NjZCtEFjcoWc8RuaN4euybRPrrulMWwL2W28KZotVtRXNKlo_VM"

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const BASE_DATE = new Date(1997, 3, 21);

import sha1 from "js-sha1";

export const REVIEWS_HEADING = {
	allergy: 'Allergy and Immune',
	bladder: 'Bladder & Sexual Function (Genitourinary)',
	cardio: 'Cardiovascular and Respiratory',
	constitutional: 'Constitutional',
	endocrine: 'Endocrine',
	enmt: 'Ear, Nose, Mouth, and Throat',
	eye: 'Eyes',
	gastro: 'Gastrointestinal',
	hemotologic: 'Hemotologic (blood) lymphatic',
	memory: 'Memory, Thinking, Mood, Psychiatric',
	musculo: 'Musculoskeletal',
	neurological: 'Neurological',
	skin: 'Skin'
}

// const _REVIEWS_HEADING = {
//     "bladder": "Bladder & Sexual Function (Genitourinary)",
//     "cardio": "Cardiovascular and Respiratory",
//     "constitutional": "Constitutional",
//     "enmt": "Ear, Nose, Mouth, and Throat",
//     "eye": "Eyes",
//     "gastro": "Gastrointestinal",
//     "neurological": "Neurological",
//     "skin": "Skin",
//     "musculo": "Musculoskeletal",
//     "endocrine": "Endocrine",
//     "memory": "Memory, Thinking, Mood, Psychiatric",
//     "hemotologic": "Hemotologic (blood) lymphatic",
//     "allergy": "Allergy and Immune"
// };

// // Sort by keys
// export const REVIEWS_HEADING = Object.keys(_REVIEWS_HEADING)
//     .sort() // Sort keys alphabetically
//     .reduce((obj, key, arr) => {
//         obj[key] = arr[key];
//         return obj;
//     }, {});

export const REVIEWS = {
    "bladder": ["Discomfort and burning","Loss of bladder control","Loss of desire for sex","Menopause(women)","Trouble with erection(men)","Urgency to urinate"],
    "cardio": ["Chest pain","Shortness of breath","Palpitations"],
    "constitutional": ["Fatigue", "Fevers","Weight gain","Weight loss","Excessive daytime sleepiness","Trouble getting to sleep","Low energy","Trouble staying asleep"],
    "enmt": ["Ringing in the ears","Loss of sense of smell","Hearing loss"],
    "eye": ["Blurred vision", "Double vision","Loss of vision"],
    "gastro": ["Nausea","Vomiting","Diarrhea","Constipation","Heartburn"],
    "neurological": ["Confusion","Falling down","Headache","Incoordination","Involuntary movements or jerking","Lighheaded or dizzy","Loss of consciousness/fainting/passing out","Numbness","Seizures or convulsion","Spinning or vertigo","Tingling","Tremor","Trouble speaking","Trouble swallowing","Trouble walking","Weakness"],
    "skin": ["Change in hair or nails","Change in skin color","Itching","Rash"],
    "musculo": ["Back pain","Join pain or swelling","Muscle pain or cramps","Neck pain"],
    "endocrine": ["Heat or cold intolerance","Increased thirst", "Loss of hair"],
    "memory": ["Anxiety","Depressed mood","Hallucinations(seeing or hearing things)","Memory loss"],
    "hemotologic":["Anemia","Easy bruising or bleeding","Slow to heal after cut"],
    "allergy":["Allergic rection to medicine or x-ray dye"]
}


export const REVIEW_OPTIONS = (key, value) => { 

	if(typeof value === "undefined") {
		value = null;
	}

	let selectedVal = value === null ? (r) => false : (r) => value.indexOf(r) !== -1;

	let v = REVIEWS[key];

	v = v.map( (i) => {
		return {
			label: i,
			value: sha1(i.toLowerCase()),
			selected: selectedVal(sha1(i.toLowerCase())),
		}
	});

	return v;

}
